<template>
  <div :class="{ 'pt-20 md:pt-[115px]': simpleLayout }">
    <HeroBanner
      v-if="banner && isHeroBannerRendered"
      :class="heroBannerClasses"
      :image="banner.image"
      :text-color="banner.textColor"
    >
      <Heading :badge="badge" :text-color="banner.textColor" :title="title" />
    </HeroBanner>

    <Breadcrumb
      v-if="!simpleLayout"
      :current-page-title="titleBreadcrumb"
      :intermediary-path="intermediaryPath"
      @breadcrumb-clicked="
        (breadcrumbTitle) =>
          handleClickEvent({ title: breadcrumbTitle, type: 'Breadcrumb' })
      "
    />

    <RevContainer>
      <Heading
        v-if="isRegularTitleRendered"
        :badge="badge"
        class="mx-0 mb-32"
        :class="regularTitleClasses"
        data-test="heading"
        :title="title"
      />
    </RevContainer>

    <Container
      v-for="(block, index) in blocks"
      :key="block.id"
      :width="getBlockWidth(block.type)"
    >
      <BlockRenderer
        :id="block.id"
        :block-position="index + 1"
        :data-test="block.id"
        :hidden-devices="block.hiddenDevices"
        :props="block.props"
        :type="block.type"
      />
    </Container>

    <RevContainer v-if="textSeo && hasRichText" class="pb-72">
      <RichText
        :data-selector="`${pageType}-content`"
        light-text
        :rich-text="textSeo"
      />
    </RevContainer>
  </div>
</template>

<script lang="ts" setup>
import { useRoute } from '#imports'
import { computed, onMounted } from 'vue'

import type {
  BasePageContent,
  BlockType,
} from '@backmarket/http-api/src/api-specs-content'
import RichText from '@backmarket/nuxt-layer-cms/RichText.vue'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { isEmpty } from '@backmarket/utils/object/isEmpty'
import { RevContainer } from '@ds/components/Container'

import HeroBanner from '../blocks/HeroBanner/HeroBanner.vue'
import {
  HIDE_ON_DEVICE_CLASSES,
  SHOW_ON_DEVICE_CLASSES,
} from '../constants/device-visibility-classes.constants'
import { getBlockWidth } from '../helpers/block-width.helper'
import {
  getClassesByHiddenDeviceType,
  isHiddenOnAllDevices,
} from '../helpers/devices/devices'
import { buildTrackingZone } from '../helpers/tracking/tracking'
import BlockRenderer from '../shared-components/BlockRenderer/BlockRenderer.vue'
import Breadcrumb from '../shared-components/Breadcrumb/Breadcrumb.vue'
import { getIntermediaryPath } from '../shared-components/Breadcrumb/helpers/get-intermediary-path'
import Container from '../shared-components/Container.vue'
import Heading from '../shared-components/Heading/Heading.vue'

const { trackClick, trackLandingPage } = useTracking()
const route = useRoute()

const props = withDefaults(
  defineProps<BasePageContent & { simpleLayout?: boolean }>(),
  {
    parentPageInfos: undefined,
    simpleLayout: false,
  },
)

onMounted(() => {
  if (route.name) {
    trackLandingPage({
      landingType: route.name.toString(),
      landingName: buildTrackingZone([
        route.params.pageName,
        route.params.pageCategory,
      ]),
    })
  }
})

const heroBannerClasses = computed(() => {
  if (isEmpty(props.banner)) return ''

  const { hiddenDevices } = props.banner
  const classes = HIDE_ON_DEVICE_CLASSES

  return getClassesByHiddenDeviceType({ hiddenDevices, classes })
})

const regularTitleClasses = computed(() => {
  if (isEmpty(props.banner)) return ''

  const { hiddenDevices } = props.banner
  const classes = SHOW_ON_DEVICE_CLASSES

  return getClassesByHiddenDeviceType({ hiddenDevices, classes })
})

const isHeroBannerRendered = computed(() => {
  if (isEmpty(props.banner)) return false

  const { hiddenDevices } = props.banner

  const isHiddenEverywhere = isHiddenOnAllDevices({
    devices: hiddenDevices,
  })

  return !isHiddenEverywhere && !props.simpleLayout
})

const isRegularTitleRendered = computed(() => {
  const hasHeroBanner = isHeroBannerRendered.value
  const hasRegularTitleOnSomeDeviceTypes = !!regularTitleClasses.value

  // we never show the regular title if we have a simple layout
  return (
    !props.simpleLayout && (!hasHeroBanner || hasRegularTitleOnSomeDeviceTypes)
  )
})

const intermediaryPath = computed(() => {
  const { parentPageInfos, pageType } = props

  return getIntermediaryPath({
    parentPageInfos,
    pageType,
  })
})

function handleClickEvent({
  title,
  type,
}: {
  title: string
  type: 'Breadcrumb' | BlockType
}) {
  if (route.name) {
    const { pageName, pageCategory } = route.params

    trackClick({
      zone: buildTrackingZone([route.name.toString(), pageName, pageCategory]),
      name: title,
      value: type,
    })
  }
}

const hasRichText = computed(() => !isEmpty(props.textSeo))
</script>
